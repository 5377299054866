import { callSettingType } from "./callSetting.type";

const initialState = {
  callSettings: {},
  allVoices: {},
};

function CallSettingReducer(state = initialState, action) {
  switch (action.type) {
    case callSettingType.GET_CALL_SETTING_DATA: {
      return { ...state, callSettings: action.payload[0] };
    }
    case callSettingType.GET_ALL_VOICES_DATA: {
      return {
        ...state,
        allVoices: {
          ...state.allVoices,
          ...action.payload,
        },
      };
    }
    case callSettingType.CLEAR_CALL_SETTING: {
      return { ...initialState };
    }
    case callSettingType.GET_HEALTH_CHECK_DATA: {
      return { healthCheckStatus: action.payload.data };
    }

    default:
      return state;
  }
}

export default CallSettingReducer;
