export const modalButtons = {
  previous: {
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 2,
    padding: "6px 10px",
    textTransform: "capitalize",
    boxShadow: "none",
    borderColor: "#D1D9EF",
    color: "#5F6A8A",
    "&:hover": {
      borderColor: "#666",
    },
  },
  saveButton: {
    borderRadius: 2,
    padding: "6px 10px",
    fontSize: 13,
    fontWeight: 500,
    color: "#FFFFFF",
    border: "none",
    outline: "none",
    cursor: "pointer",
    background: "#FF5E00BF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FF7E33BF",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#e69d73",
    },
  },
};
