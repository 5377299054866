import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../../assets/Images/SearchIcon.svg";
import { styled } from "@mui/material/styles";

const SearchBar = ({ searchText, handleChange, styles = {} }) => {
  return (
    <SearchField
      name="search"
      variant="outlined"
      fullWidth
      placeholder="Search"
      value={searchText || ""}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="#555555" height={14} width={14} />
          </InputAdornment>
        ),
      }}
      sx={styles}
    />
  );
};

export default SearchBar;

const SearchField = styled(TextField)(() => ({
  width: 230,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    borderWidth: 1,
    fontSize: 14,
    fontWeight: 400,
    height: 36,
    "& fieldset": {
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: "#2F353E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2F353E",
      borderWidth: 1,
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: 1.5,
    pl: 0.5,
    "&::placeholder": {
      color: "#9E9FA1",
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));
