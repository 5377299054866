import React, { lazy } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  checkRolesAccess,
  goToMentionedRoutes,
  isEmpty,
  isVodexLoggedin,
  LoadingDotsSpin,
} from "../../CommonComponents/Common";
import {
  getConstantData,
  getDashboardChartAction,
  getDashboardDataAction,
} from "../../Store/Account/Account.action";
import { getProjectsAction } from "../../Projects/Store/projects.action";
import { axiosClient } from "../../../../Constant/apiConstant";
import { getUerAccountsDetailsAction } from "../../Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";

import SkeletonLodingWithCards from "../../../../Constant/SkeletonLoding";
import TotalCalls from "../../Admin/TotalCalls";
import GatewayStatus from "../../Admin/GatewayStatus";
import Whatsapp from "../../Integration/Whatsapp/Whatsapp";
// import Home from "../../Dashboard/Dashboard";
import Home from "../../Dashboard/DashboardHome";
// import CallHistory from "../../ProjectSection/CampaignSection/CallHistory/CallHistory";
import Support from "../Support";
import packageJson from "./../../../../../package.json";
import CallHistory from "../../CallHistory/CallHistory";

const UserProfile = lazy(() => import("../../Profile/Profile"));
const ComingSoon = lazy(() => import("../../CommonComponents/ComingSoon"));
const USDPayment = lazy(() => import("../../Payments/USDPayment"));
const Projects = lazy(() => import("../../NewProjects/NewProject"));
const Admin = lazy(() => import("../../Admin"));
const AccessManager = lazy(
  () => import("../../Settings/AccessManager/AccessManager")
);
const Invite = lazy(() => import("../../AccessManger/Invite"));
const VideoComponent = lazy(() => import("../../CommonComponents/videomodal"));
const AccountHistory = lazy(
  () => import("../../Settings/AccountHistory/AccountHistory")
);
const RechargeBody = lazy(() => import("../../Admin/RechargeBody"));
const VoiceCloning = lazy(
  () => import("../../Settings/VoiceCloning/VoiceCloning")
);
const CustomSupportIframe = lazy(() => import("../CustomSupportIframe"));
const UsageData = lazy(() => import("../../Admin/UsageData"));
const TopupForm = lazy(() => import("../../Admin/TopupForm"));
const GHLVerfication = lazy(
  () => import("../../AccountSection/GHLVerification")
);
const TwillioIntegration = lazy(
  () => import("../../Integration/TwillioIntegration")
);
const GhlIntegration = lazy(() => import("../../Integration/GhlIntegration"));
const CustomSupportIframeLink = lazy(
  () => import("../CustomSupportIframeLink")
);
const Index = lazy(() => import("../../ProjectSection/Index"));
const Integration = lazy(() => import("../../Integration/Integration"));
const QueueStatus = lazy(() => import("../../Admin/QueueStatus"));
const RunningCampaigns = lazy(
  () =>
    import("../../ProjectSection/CampaignSection/CampaignRuns/RunningCampaigns")
);
const IncreaseCps = lazy(() => import("../../AccountSection/IncreaseCps"));
const IncreaseCapacity = lazy(
  () => import("../../AccountSection/IncreaseCapacity")
);

const NewRoutes = () => {
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let Stalker = locSearch.get("stalker");
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const [spin, setSpin] = useState(true);
  const [videoId, setVideoId] = useState("");
  const [versionShow, setVersionShow] = useState(false);
  const {
    AccountReducer: {
      userDetails: { email, accounts, _id, role },
      selectedAccount,
      constantData,
      accountRoles,
    },
  } = reducer;
  const currentVersion = packageJson.version;

  React.useEffect(() => {
    if (
      !isEmpty(constantData) &&
      currentVersion !== constantData?.latestVersion
    ) {
      setVersionShow(true);
    }
  }, [constantData]);

  useEffect(() => {
    const init = async () => {
      setSpin(true);
      await dispatch(getUerAccountsDetailsAction());
      // swalWarningMessage(
      //   "Oh, what a surprise! Another day of high traffic. How utterly unexpected. 😏, Give us a few seconds.",
      //   8000
      // );
    };
    init();
  }, []);

  React.useEffect(() => {
    let terry = 0;
    const script = document.createElement("script");
    if (window.location.origin === "https://aivoicelogin.com") {
      terry = 1;
      script.onload = () => {
        (window as any).voiceflow.chat.load({
          verify: { projectID: "65840d87c9073351e401aa85" },
          url: "https://general-runtime.voiceflow.com",
          versionID: "production",
        });
      };
      script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    return () => {
      if (terry > 0) {
        document.getElementsByTagName("head")[0].removeChild(script);
      }
    };
  }, []);

  React.useEffect(() => {
    const script = document.createElement("script");
    let kl = 0;
    let cem = 0;

    if (window.location.origin === "https://voice.acquisitionai.com") {
      script.async = true;
      script.src = "https://public.produktly.com/js/main.js";
      script.setAttribute("id", "produktlyScript");
      script.dataset.clientToken =
        "a428727aeeaa48faf71d98f2ebbaacbc5bc66ca7f9b14413e33d071dee4d919f0541d9699be0d760220639b279bc7735039de009cba805d2d7cc51dfe3229d6fa0f9a209d7a30119a71f61b4c61837ee9e69be23e46bbe98ed7b2b525623881bf2606c37";
      document.head.appendChild(script);
      cem = 1;
    }

    return () => {
      if (kl > 0) {
        document.body.removeChild(script);
      }
      if (cem > 0) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const { pathname } = useLocation();
  const projectId = pathname?.split("/")[3];

  React.useEffect(() => {
    if (!projectId) {
      axiosClient.defaults.headers.common["projectId"] = null;
    }
  }, [projectId]);
  React.useEffect(() => {
    if (!isEmpty(selectedAccount)) {
      axiosClient.defaults.headers.common[
        "dbUrl"
      ] = `${selectedAccount?.accountUrl}`;
      axiosClient.defaults.headers.common["projectId"] = projectId;
      const init = async () => {
        await goToMentionedRoutes(
          selectedAccount,
          _id,
          history,
          dispatch,
          true
        );
        await dispatch(getConstantData());
        setSpin(false);
        await dispatch(getDashboardDataAction());
        await dispatch(getDashboardChartAction());
        await dispatch(getProjectsAction());
      };
      init();
    }
  }, [selectedAccount]);

  let pathName = window.location.pathname;

  React.useEffect(() => {
    let find = constantData?.videosLink?.find((x) => {
      if (x.path.includes("projectId")) {
        let newPath = x.path.replace("projectId", projectId);
        return newPath === pathName ? true : false;
      } else {
        return x.path === pathName;
      }
    });
    if (find) {
      setVideoId(find.videoId);
    } else {
      setVideoId("");
    }
  }, [pathName, constantData]);
  const [access, setAccess] = useState("");

  React.useEffect(() => {
    let acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  return (
    <div>
      {selectedAccount?.isPaused && (
        <div className="transparent-alert word-wrap">
          {selectedAccount?.pauseMsg}
        </div>
      )}
      {versionShow && (
        <div className="transparent-alert-black">
          <p>
            For the latest enhancements, execute a hard refresh as your current
            version is not the latest one available.
          </p>
        </div>
      )}
      <div>
        {spin ? (
          <SkeletonLodingWithCards count={4} />
        ) : (
          <React.Suspense fallback={<LoadingDotsSpin />}>
            {constantData?.logAccess?.includes(Stalker) && (
              <Route path="/account/queue">
                <QueueStatus />
              </Route>
            )}
            {constantData?.logAccess?.includes(Stalker) && (
              <Route path="/account/all-calls">
                <TotalCalls />
              </Route>
            )}
            {constantData?.logAccess?.includes(Stalker) && (
              <Route path="/account/gateways">
                <GatewayStatus />
              </Route>
            )}
            <Switch>
              <Route path="/account/whatsapp-integration">
                <Whatsapp />
              </Route>
              <Route path="/account/project/:projectId/">
                <Index />
              </Route>
              <Route path="/account/call-history">
                <CallHistory />
              </Route>
              <Route path="/account/support-email">
                <Support />
              </Route>
              <Route path="/account/payments">
                {selectedAccount?.currency === "inr" ? (
                  <USDPayment />
                ) : isVodexLoggedin() ? (
                  <USDPayment />
                ) : (
                  <USDPayment />
                )}
              </Route>
              {access === "dashboard" && (
                <Route path="/account/voice-cloning">
                  {isVodexLoggedin() ? <VoiceCloning /> : <VoiceCloning />}
                </Route>
              )}
              {access === "dashboard" && (
                <Route path="/account/invite">
                  <Invite />
                </Route>
              )}
              {access === "dashboard" && (
                <Route path="/account/access">
                  <AccessManager />
                </Route>
              )}
              {access === "dashboard" && (
                <Route path="/account/history">
                  <AccountHistory />
                </Route>
              )}
              <Route path="/account/profile">
                <UserProfile />
              </Route>
              <Route path="/account/projects">
                <Projects />
              </Route>
              <Route path="/account/topup">
                <RechargeBody />
              </Route>
              <Route path="/account/integrations">
                <Integration />
              </Route>
              <Route path="/account/running-campaign">
                <RunningCampaigns />
              </Route>
              <Route path="/account/capacity-settings">
                <IncreaseCapacity />
              </Route>
              <Route path="/account/cps-settings">
                <IncreaseCps />
              </Route>
              <Route path="/account/twillio-integration">
                <TwillioIntegration />
              </Route>
              {constantData?.ghlShow?.includes(email) && (
                <Route path="/account/ghl-integration">
                  <GhlIntegration />
                </Route>
              )}
              <Route path="/account/crm-verification">
                <GHLVerfication />
              </Route>
              {(role === "sales" || role === "super_admin") && (
                <Route path="/account/account-topup">
                  <TopupForm />
                </Route>
              )}

              {role === "super_admin" && (
                <Route path="/account/usage">
                  <UsageData />
                </Route>
              )}
              {access === "dashboard" && (
                <Route path="/account/dashboard">
                  <Home />
                </Route>
              )}
              {window.location.origin === "https://app.gencallsystem.com" && (
                <Route path="/account/help-desk">
                  <CustomSupportIframeLink link={" https://gencallsystem.eu"} />{" "}
                </Route>
              )}

              {window.location.origin === "https://app.voizer.ai" && (
                <Route path="/account/help-desk">
                  <CustomSupportIframe />
                </Route>
              )}
              {window.location.origin === "https://aivoicelogin.com" && (
                <Route path="/account/training">
                  <CustomSupportIframeLink
                    link={"https://www.closerbot.ai/training-page"}
                  />
                </Route>
              )}
              {(window.location.origin === "https://app.voizer.ai" ||
                (window.location.origin === "https://ghl.agencys.ai" &&
                  role === "admin")) && (
                <Route path="/account/help-desk">
                  <CustomSupportIframe />
                </Route>
              )}

              {(role === "super_admin" || role === "admin") && (
                <>
                  <Route path="/account/admin">
                    <Admin />
                  </Route>
                  <Route path="/account/recharge">
                    <RechargeBody />
                  </Route>
                </>
              )}
            </Switch>
          </React.Suspense>
        )}
      </div>
    </div>
  );
};

export default NewRoutes;
