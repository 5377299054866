import React from "react";
import { Box } from "@mui/material";
import Select, { components } from "react-select";

import { ReactComponent as FilterIcon } from "../../../../assets/Images/FilterIcon.svg";
import { styles } from "./styles";

const CustomPlaceholder = (props: any) => (
  <components.Placeholder {...props}>
    <span style={{ marginRight: "13px" }}>
      <FilterIcon />
    </span>
    {props.children}
  </components.Placeholder>
);

const FilterHeading = ({
  filterName,
  saveFilter,
  setFilterName,
  savedFilters,
  handleFilterSelect,
}) => {
  return (
    <>
      <Box className="flex-column" sx={{ gap: "10px" }}>
        <span style={{ fontSize: "14px", fontWeight: 600 }}>Apply Filters</span>
        <span className="font-12-lightgrey">
          Filter table data and save filters.
        </span>
        {saveFilter ? (
          <Box
            sx={{
              border: "1px solid #CCD2DE",
              borderRadius: "8px",
              padding: "0 16px",
              margin: "10px 0 20px 0",
            }}
            className="flex-align-center"
          >
            <FilterIcon />
            <input
              onChange={(e) => setFilterName(e.target?.value)}
              name="filterName"
              value={filterName}
              className="custm-ip ml-3"
              style={{ height: "40px" }}
              placeholder="Enter a filter name"
            />
          </Box>
        ) : (
          <Select
            maxMenuHeight={180}
            className="basic-single mt-2"
            classNamePrefix="select"
            isSearchable={false}
            options={savedFilters}
            value={savedFilters?.find(
              (filter: any) => filter?.filterName === filterName || null
            )}
            getOptionValue={(e: any) => e.filterName}
            getOptionLabel={(e: any) => e.filterName}
            placeholder="Select saved filter"
            styles={styles.customStyle}
            onChange={(e) => {
              handleFilterSelect(e);
            }}
            components={{
              Placeholder: CustomPlaceholder,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default FilterHeading;
