import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const SidebarContainer = styled(Box)(({ theme, drawerOpen }) => ({
  width: 200,
  backgroundColor: "#202020",
  height: "95%",
  position: "fixed",
  left: drawerOpen ? `calc(264px + 1px)` : `calc(${theme.spacing(9)} + 1px)`,
  display: "flex",
  flexDirection: "column",
  zIndex: 10,
}));

const SidebarItem = styled(ListItem)(({ active }) => ({
  width: "auto",
  color: "#A0A0A0",
  cursor: "pointer",
  backgroundColor: active ? "#121212" : "transparent",
  "&:hover": {
    backgroundColor: "#121212",
  },
  borderLeft: active ? "4px solid #FF6600" : "4px solid black",
  paddingLeft: active ? "8px" : "12px",
  margin: "0px 16px ",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
}));

const SidebarText = styled(Typography)(() => ({
  color: "#9E9FA1",
  fontSize: 14,
  fontWeight: 500,
  marginLeft: 8,
}));

const SecondarySection = styled(Box)({
  marginTop: "auto",
  marginBottom: 32,
});

export default function ProjectSidebar({ drawerOpen }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const projectId = pathname.split("/")[3];
  const reducer = useSelector((reducer) => reducer);

  const {
    AccountReducer: { selectedAccount },
  } = reducer;

  const menuItems = [
    {
      id: "agents",
      text: "Agents",
      to: `/account/project/${projectId}/agents`,
    },
    {
      id: "audience",
      text: "Audience",
      to: `/account/project/${projectId}/audiences`,
    },
    {
      id: "campaigns",
      text: "Campaigns",
      to: `/account/project/${projectId}/campaigns`,
    },
  ];

  const secondaryMenu = [
    {
      id: "call_settings",
      text: "Call settings",
      to: `/account/project/${projectId}/call-settings`,
    },
    {
      id: "messaging",
      text: "Messaging",
      to: `/account/project/${projectId}/common`,
    },
  ];
  const secondaryMenuItems = selectedAccount?.params?.dialerConfig
    ? [
        ...secondaryMenu,
        {
          id: "dialer_config",
          text: "Dialer config",
          to: `/account/project/${projectId}/dialer-config`,
        },
      ]
    : secondaryMenu;


  const isActive = (itemPath) => {
    if (pathname === itemPath) return true;

    if (
      pathname.includes("/campaign") ||
      pathname.includes("/schedule-campaign") ||
      pathname.includes("/all-runs-details")
    ) {
      return itemPath.includes("/campaigns");
    }

    return false;
  };

  return (
    <SidebarContainer drawerOpen={drawerOpen}>
      <List>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.id}
            onClick={() => history.push(item.to)}
            active={isActive(item.to)}
          >
            <Box display="flex" alignItems="center">
              <SidebarText>{item.text}</SidebarText>
            </Box>
          </SidebarItem>
        ))}
      </List>

      <SecondarySection>
        <Typography color="#9E9FA1" fontSize={14} fontWeight={500} ml={2}>
          Project settings
        </Typography>
        <List>
          {secondaryMenuItems.map((item) => (
            <SidebarItem
              key={item.id}
              onClick={() => history.push(item.to)}
              active={isActive(item.to)}
            >
              <Box display="flex" alignItems="center">
                <SidebarText>{item.text}</SidebarText>
              </Box>
            </SidebarItem>
          ))}
        </List>
      </SecondarySection>
    </SidebarContainer>
  );
}
