import { Alert, Box } from "@mui/material";
import React from "react";
import BaseButton from "../../Common/Buttons/BaseButton";

const DurationFilter = ({
  mainState,
  arraMapped,
  allCallStatus,
  outComefilter,
  onChange,
  customDurationError,
  handleClearDurationFilter,
  onCustomDurationChange,
}) => {
  return (
    <Box className="mt-2">
      <span className="font-12-grey">Duration</span>
      <Box
        className="mt-2"
        sx={{
          height: "40px",
          background: "#F1F4FA",
          borderRadius: "100px",
          padding: "0 4px",
        }}
      >
        {arraMapped.map((arr, index) => (
          <Box key={index} sx={{ paddingTop: "4px" }}>
            <Box className="flex-evenly">
              {arr.innerMap?.map((innerArr, i) => {
                const dur = arr?.title === "Filter Calls with status";
                const isSelected = arr?.isObject
                  ? outComefilter.find((o) => o === innerArr?.tag)
                  : mainState[arr?.valueAssigned] === innerArr ||
                    mainState[arr?.valueAssigned].includes(innerArr);

                return (
                  <Box
                    key={i}
                    onClick={() => onChange(arr?.valueAssigned, innerArr)}
                    sx={{
                      height: "30px",
                      background: isSelected ? "#FFFFFF" : "#F1F4FA",
                      borderRadius: "100px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      px: 0.5,
                    }}
                    className="font-12-lightgrey"
                  >
                    {!arr?.isObject &&
                      (dur ? allCallStatus[innerArr] : innerArr)}
                    {arr?.isObject && innerArr?.name}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
      {mainState?.durationFilter === "Custom" && (
        <Box>
          <span className="font-12-lightgrey mt-2">Custom Duration</span>
          <Box className="flex-between">
            <input
              style={{ width: "160px" }}
              placeholder="from (in sec.)"
              name="min"
              min={0}
              type="number"
              className="form-control custm-ip mr-1"
              value={mainState?.min}
              onChange={onCustomDurationChange}
            />
            <input
              style={{ width: "160px" }}
              placeholder="to (in sec.)"
              name="max"
              min={1}
              type="number"
              className="form-control custm-ip"
              value={mainState?.max}
              onChange={onCustomDurationChange}
            />
          </Box>
          {customDurationError && (
            <Alert severity="error" variant="standard" sx={{ my: 1 }}>
              {customDurationError}
            </Alert>
          )}
        </Box>
      )}
      <BaseButton
        title="Clear Duration Filter"
        handleClick={handleClearDurationFilter}
        styles={{ padding: 0 }}
      />
    </Box>
  );
};

export default DurationFilter;
