import React from "react";
import { LoadingSlides } from "../Common";
import { ReactComponent as CrossIcon } from "../../../../assets/Images/CrossIcon.svg";
import { Modal } from "react-bootstrap";
import "./CommonModal.css";
import { Box, Button } from "@mui/material";
import { modalButtons } from "./ModalStyles";

const CommonModal = ({
  heading,
  body,
  show,
  toggle,
  size = 600,
  handleSave,
  handlePrevious = () => {},
  saveButtonText = "Save",
  spin = false,
  centered = false,
  style = {},
  footer = true,
  handleNext = () => {},
  nextButtonText = "",
  showSaveButton = true,
  showNextButton = false,
  showPreviousButton = false,
  disableNextButton = false,
  disableSaveButton = false,
  customClassName = "",
}) => {
  return (
    <div>
      <Modal
        contentClassName={`common-modal-${size || 600} ${customClassName}`}
        show={show}
        onHide={toggle}
        backdrop="static"
        keyboard={false}
        centered={centered}
      >
        <Modal.Header className="new-modal-header">
          <span
            className="font-14-600-grey"
            style={{ textTransform: "capitalize" }}
          >
            {heading}
          </span>
          <span onClick={toggle} className="modal-cross-icon">
            <CrossIcon />
          </span>
        </Modal.Header>
        <Modal.Body className="new-modal-body">
            {body}
        </Modal.Body>
        {footer && (
          <Modal.Footer className="new-modal-footer">
            <Box
              className="flex-align-center"
              sx={{
                justifyContent: `${
                  showNextButton && showPreviousButton
                    ? "space-between"
                    : "flex-end"
                }`,

                gap: "13px",
                width: "100%",
              }}
            >
              {showPreviousButton && (
                <Button
                  variant="outlined"
                  sx={modalButtons.previous}
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              )}

              <Box className="flex-xy-center">
                {showNextButton && (
                  <Button
                    className="mr-2"
                    disabled={disableNextButton}
                    variant="outlined"
                    sx={modalButtons.previous}
                    onClick={nextButtonText === "Back" ? toggle : handleNext}
                  >
                    {nextButtonText}
                  </Button>
                )}
                {showSaveButton && (
                  <Button
                    disabled={spin || disableSaveButton}
                    sx={modalButtons.saveButton}
                    onClick={handleSave}
                  >
                    {spin ? (
                      <div className="flex-xy-center">
                        <LoadingSlides bgColor="white" />
                      </div>
                    ) : (
                      saveButtonText
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default CommonModal;
