import React from "react";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as NextIcon } from "../../../../assets/icons/next.svg";
import { ReactComponent as PreviousIcon } from "../../../../assets/icons/previous.svg";
import { ReactComponent as EmptyStateIcon } from "../../../../assets/icons/empty-state.svg";
import styles from "./DynamicTableStyles";
import { shortenString } from "../../../../Constant/helper";
import { LoadingSlides, PAGE_SIZE_50 } from "../Common";

const DynamicTable = ({
  columns,
  data,
  loading = false,
  totalPages = 0,
  currentPage = 0,
  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {},
  handlePrevious = () => {},
  handleNext = () => {},
  noDataAvailableMessage = "No Data Available",
  style = {},
  showTotalRows = false,
  pagination = true,
  rowCount = 0,
  highlightRowCondition = (row, rowIndex) => false,
}) => {
  return (
    <>
      {loading && (
        <Box sx={styles.loaderContainer}>
          <LoadingSlides bgColor="#FF5E00" />
        </Box>
      )}
      {data?.length > 0 ? (
        <Box>
          <TableContainer
            elevation={0}
            component={Paper}
            sx={{
              ...styles.tableContainer,
              height: "auto",
              ...style,
            }}
          >
            {data?.length > 0 && (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((col, index) => (
                      <TableCell key={index} sx={styles.header}>
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...styles.tableRow,
                        ...(highlightRowCondition(row, rowIndex) &&
                          styles.highlightedRow),
                      }}
                    >
                      {columns.map((col, colIndex) => (
                        <TableCell
                          key={colIndex}
                          sx={{
                            ...styles.cell,
                            ...(col.styles || {}),
                          }}
                        >
                          {col.render
                            ? col.render(row, rowIndex)
                            : row[col.field]
                            ? shortenString(row[col.field], 40)
                            : "--"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <Stack direction="row" sx={styles.bottomContainer}>
            <Box color="#555555" display="flex" gap={1.5} alignItems="center">
              <Typography fontSize={12} fontWeight={500}>
                Total{" "}
              </Typography>
              <Typography
                component="span"
                fontSize={12}
                fontWeight={500}
                border={1}
                borderColor="#CCD2DE"
                px={1.5}
                py={0.25}
                borderRadius={2}
              >
                {showTotalRows ? `${rowCount} rows` : `${totalPages} pages`}
              </Typography>
            </Box>

            {pagination && rowCount > PAGE_SIZE_50 && (
              <>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                  size="medium"
                  variant="outlined"
                  siblingCount={0}
                  boundaryCount={2}
                  hidePrevButton
                  hideNextButton
                  sx={styles.pagination}
                />

                <Box display="flex" gap={3}>
                  <Button
                    variant="outlined"
                    startIcon={
                      <PreviousIcon
                        style={{
                          opacity: currentPage === 1 ? 0.5 : 1,
                          color: currentPage === 1 ? "#B0B0B0" : "#666666",
                        }}
                      />
                    }
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    sx={styles.paginationButtons}
                  >
                    Previous
                  </Button>

                  <Button
                    variant="outlined"
                    endIcon={
                      <NextIcon
                        style={{
                          opacity: currentPage === totalPages ? 0.5 : 1,
                          color:
                            currentPage === totalPages ? "#B0B0B0" : "#666666",
                        }}
                      />
                    }
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                    sx={styles.paginationButtons}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      ) : (
        <Box
          my={1}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyItems="center"
          justifyContent="center"
          alignItems="center"
          gap={2}
          p={3}
          position="relative"
        >
          <Typography
            fontSize={20}
            fontWeight={500}
            color="#555555"
            textTransform="capitalize"
            textAlign="center"
            position="absolute"
            top={90}
            left="50%"
            sx={{
              transform: "translateX(-50%)",
            }}
          >
            {noDataAvailableMessage} ...
          </Typography>
          <Box>
            <EmptyStateIcon />
          </Box>
        </Box>
      )}
    </>
  );
};

export default DynamicTable;
