import { createTheme } from "@mui/material";
import { ReactComponent as ArrowDownwardIcon } from "./assets/icons/arrow-downward.svg";

export const muiTheme = createTheme({
  components: {
    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDownwardIcon,
      },
      styleOverrides: {
        icon: {
          right: 10,
          top: "25%",
        },
      },
    },
  },
});
