import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { renderThumb } from "./Common";

function CustomScrollbars(props) {
  return (
    <Scrollbars
      style={{
        height: props.height || "85vh",
        color: props.color || "white",
        borderRadius: "10px",
      }}
      renderThumbVertical={renderThumb}
      autoHide={true}
    >
      {props.children}
    </Scrollbars>
  );
}

export default CustomScrollbars;
