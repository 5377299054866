import { Box, Typography } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import { convertDate } from "../CommonComponents/Common";

interface BarChartProps {
  chartData: Record<string, number>;
  state: any;
}

const BarChart: React.FC<BarChartProps>  = ({ chartData, state }) => {
  const sortedEntries = Object.entries(chartData).sort(
    ([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime()
  );

  const labels = sortedEntries.map(([date]) => date);
  const dataValues = sortedEntries.map(([_, value]) => value);
  const data = {
    labels,
    datasets: [
      {
        label: "Calls",
        data: dataValues,
        backgroundColor: "rgba(22, 200, 199, 0.6)",
        hoverBackgroundColor: "rgba(22, 200, 199, 1)",
        borderRadius: 8,
        maxBarThickness: 54,
        borderSkipped: false,
      },
    ],
  };

  const options: any = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Days",
          color: "#555555",
          font: {
            size: 14,
            weight: "500",
          },
          padding: { top: 10 },
        },
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: { display: false },
        border: { display: false },
      },
      y: {
        title: {
          display: true,
          text: "Calls",
          color: "#555555",
          font: {
            size: 14,
            weight: "500",
          },
          padding: { bottom: 10 },
        },
        ticks: {
          beginAtZero: true,
          color: "#888",
          font: { size: 12 },
          callback: (value) => `${value}`,
        },
        grid: {
          color: "#fff",
          borderWidth: 0,
        },
        border: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: "#000",
        titleFont: { size: 14, weight: "600" },
        bodyFont: { size: 12, weight: "400" },
        displayColors: false,
        padding: 12,
        callbacks: {
          label: (context) => `\n  ${context.raw} Calls  \n`,
        },
        bodyAlign: "center",
        titleAlign: "center",
      },
    },
  };

  return (
    <Box
      sx={{
        border: "1px solid #D1D9EF",
        borderRadius: 2,
        padding: 2,
        margin: "0 auto",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={0.5}
        >
          <Typography color="#555555" fontSize={14} fontWeight={500}>
            Calls Triggered
          </Typography>
          <Typography color="#999999" fontSize={12} fontWeight={500}>
            - in{" "}
            {state?.from && state?.to
              ? `${convertDate(state?.from)} - ${convertDate(
                  state?.to
                )}`
              : "Last 7 Days"}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          height: "300px",
          padding: "16px",
        }}
      >
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default BarChart;
