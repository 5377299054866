import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { leftMenuStyle } from "./styles";

import { ReactComponent as CallHistoryIcon } from "../../../../assets/Images/CallHistoryIcon.svg";
import { ReactComponent as DasboardIcon } from "../../../../assets/Images/Dashboard.svg";
import { ReactComponent as LeftPointer } from "../../../../assets/Images/LeftPointer.svg";
import { ReactComponent as ProjectIcon } from "../../../../assets/Images/ProjectIcon.svg";
import { ReactComponent as QuickStartIcon } from "../../../../assets/Images/Quick start.svg";
import { ReactComponent as RightPointer } from "../../../../assets/Images/RightPointer.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/Images/SettingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/Images/SupportIcon.svg";
import { ReactComponent as AdminIcon } from "../../../../assets/Images/Admin.svg";

import NewRoutes from "./NewRoutes";
import ProjectSidebar from "./ProjectSidebar";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useSelector } from "react-redux";
import { checkRolesAccess } from "../../CommonComponents/Common";

const menu = [
  {
    id: "quick_start",
    text: "Quick start",
    icon: <QuickStartIcon />,
    to: "/onboarding?from=dashboard",
  },
  {
    id: "dashboard",
    text: "Dashboard",
    icon: <DasboardIcon />,
    to: "/account/dashboard",
  },
  {
    id: "projects",
    text: "Projects",
    icon: <ProjectIcon />,
    to: "/account/projects",
  },
  {
    id: "call_history",
    text: "Call History",
    icon: <CallHistoryIcon />,
    to: "/account/call-history",
  },
];

const secondaryMenuItems = [
  {
    id: "admin",
    text: "Admin Control",
    icon: <AdminIcon />,
    to: "access",
  },
  {
    id: "settings",
    text: "Settings",
    icon: <SettingsIcon />,
    to: "/account/access",
  },
  {
    id: "support",
    text: "Support",
    icon: <SupportIcon />,
    to: "/account/support-email",
  },
];

const adminRoutes = [
  { id: "admin", to: "/account/admin" },
  {
    to: "/account/account-topup",
  },
  {
    to: "/account/recharge",
  },
];

const settingsRoutes = [
  { to: "/account/access" },
  {
    to: "/account/voice-cloning",
  },
  { to: "/account/history" },
  { to: "/account/integrations" },
];

export default function MainLayout() {
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { accountRoles },
  } = reducer;
  const {
    AccountReducer: {
      userDetails: { role },
    },
  } = reducer;
  const [open, setOpen] = useState(false);
  const [activeItemId, setActiveItemId] = useState("dashboard");
  const [showSettings, setShowSettings] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [access, setAccess] = useState("");

  const location = useLocation();
  const currentPath = location?.pathname;
  const theme = useTheme();
  const styles = leftMenuStyle(theme, open);
  const isProjectDetailPage =
    location.pathname.startsWith("/account/project/") &&
    location.pathname !== "/account/projects";

  useEffect(() => {
    let acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  useEffect(() => {
    setOpen(false);
  }, [isProjectDetailPage]);

  useEffect(() => {
    const updateActiveItem = () => {
      if (currentPath.includes("/account/project/")) {
        return "projects";
      }
      const activeMenu = [...menu, ...secondaryMenuItems].find(
        (item) => item.to === currentPath
      );
      if (activeMenu) {
        return activeMenu.id;
      }

      if (settingsRoutes.some((route) => route.to === currentPath)) {
        return "settings";
      }
      if (adminRoutes.some((route) => route.to === currentPath)) {
        return "admin";
      }
      return null;
    };

    const activeId = updateActiveItem();
    if (activeId) {
      setActiveItemId(activeId);
    }
  }, [currentPath]);

  const handleDrawer = () => setOpen((prev) => !prev);
  const menuItems = menu.filter((item) => {
    if (item.id === "quick_start") {
      return checkRolesAccess(accountRoles, "projectPage");
    }

    if (item.id === "dashboard") {
      return access === "dashboard";
    }
    return true;
  });

  const secondaryMenu = secondaryMenuItems?.filter((item) => {
    if (item.id === "settings") {
      return access === "dashboard";
    }
    if (item.id === "admin") {
      return role === "super_admin" || role === "admin" || role === "sales";
    }
    return true;
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100vh",
        backgroundColor: "#181818",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          height: 64,
          zIndex: 100,
          position: "relative",
        }}
      >
        <Topbar
          showAdminControls={showAdmin}
          styles={styles}
          open={open}
          showSettings={showSettings}
          theme={theme}
        />
      </Box>
      {!isProjectDetailPage && (
        <Box sx={open ? styles.leftPointer : styles.rightPointer}>
          {open ? (
            <LeftPointer onClick={handleDrawer} />
          ) : (
            <RightPointer onClick={handleDrawer} />
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <Sidebar
          styles={styles}
          menuItems={menuItems}
          open={open}
          handleDrawer={handleDrawer}
          setShowSettings={setShowSettings}
          activeItemId={activeItemId}
          setActiveItemId={setActiveItemId}
          secondaryMenuItems={secondaryMenu}
          setShowAdmin={setShowAdmin}
          adminRoutes={adminRoutes}
          settingsRoutes={settingsRoutes}
          isProjectDetailPage={isProjectDetailPage}
        />
        {isProjectDetailPage && <ProjectSidebar drawerOpen={open} />}
        <Box
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "hidden",
            marginLeft: isProjectDetailPage ? "200px" : "0",
            transition: "margin-left 0.3s ease",
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: "12px",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                px: 2,
                pt: 2,
                flex: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              <NewRoutes />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
