import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StatusButtonProps {
  status: string;
  statusColor?: string;
  backgroundColor?: string;
}

const StyledStatusButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "statusColor" && prop !== "backgroundColor",
})<{
  statusColor?: string;
  backgroundColor?: string;
}>(({ statusColor, backgroundColor }) => ({
  fontSize: 12,
  fontWeight: 500,
  textTransform: "capitalize",
  borderRadius: 8,
  backgroundColor: backgroundColor || "transparent",
  border: "none",
  color: statusColor || "#333333",
  paddingY: "1px",
  display: "flex",
  alignItems: "center",
  textWrap: "nowrap",
  "&:hover": {
    cursor: "default",
    backgroundColor: backgroundColor || "transparent",
    border: "none",
    color: statusColor || "#333333",
  },
}));

const StatusButton: React.FC<StatusButtonProps> = ({
  status,
  statusColor = "#333333",
  backgroundColor = "f0f0f0",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <StyledStatusButton
        variant="outlined"
        size="small"
        backgroundColor={backgroundColor}
        statusColor={statusColor}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: statusColor || "#333333",
            mr: 0.75,
          }}
        />
        {status}
      </StyledStatusButton>
    </Box>
  );
};

export default StatusButton;
