export const styles = {
  customStyle: {
    control: (base, state) => ({
      ...base,
      borderRadius: "8px",
      fontSize: "13px",
      padding: "4px",
      color: "#55555",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#9E9FA1",
      fontSize: "14px",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#55555",
      fontSize: "13px",
    }),
    indicatorSeparator: () => ({
      display: "none",
      color: "#555555",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "8px",
      marginTop: "4px",
      zIndex: 9999,
      fontSize: "13px",
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "13px",
      color: state.isSelected ? "#FFFFFF" : "#212529",
      padding: "10px 15px",
      cursor: "pointer",
    }),
  },
  scrollBarX: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  clearFilter: {
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
    color: "#555555",
    marginLeft: "10px",
    marginTop: "3px",
    "&:hover": { color: "#181818" },
  },
};
