import React, { useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/sort.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import useDebounce from "../../Hooks/useDebounce";
import SearchBar from "../Common/Search/SearchBar";
import BaseButton from "../Common/Buttons/BaseButton";

const CallHistoryHeader = ({
  setIsReady,
  setSearchText,
  exportToCSV,
  toggleFilterDrawer,
  totalCalls,
  exportLoad,
  searchTerm,
  setSearchTerm,
  fetchCallHistory,
  loading,
}) => {
  const debouncedSearchTerm = useDebounce(searchTerm, 3000);

  useEffect(() => {
    setIsReady(true);
    setSearchText(debouncedSearchTerm);
    localStorage.setItem("callHistorySearchTerm", debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      sx={{ marginBottom: 2 }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        <Typography color="#181818" fontSize={20} fontWeight={600}>
          Call History
        </Typography>
        <Typography
          color="#555555"
          component="span"
          fontSize={14}
          fontWeight={500}
          border={1}
          borderColor="#CCD2DE"
          px={1.5}
          py={0.25}
          borderRadius="9px"
        >
          Total calls{" "}
          <span style={{ color: "#55555599", fontSize: 20, fontWeight: 700 }}>
            {totalCalls || 0}
          </span>
        </Typography>
      </Box>
      <Box display="flex" gap={1}>
        <SearchBar
          searchText={searchTerm}
          handleChange={(e) => setSearchTerm(e.target.value)}
        />
        <BaseButton
          title="Export"
          variant="outlined"
          startIcon={exportLoad || <ExportIcon height={14} width={14} />}
          loading={exportLoad}
          handleClick={exportToCSV}
          styles={{ px: 2, fontSize: 12 }}
        />
        <IconButton
          title="Refresh"
          onClick={fetchCallHistory}
          sx={refreshButtonStyles}
          disabled={loading}
        >
          <RefreshIcon className="refresh-icon" height={14} width={14} />
        </IconButton>
        <IconButton
          title="Apply Filters"
          onClick={toggleFilterDrawer}
          sx={{
            px: 1.05,
            py: 0,
            borderRadius: 2,
            color: "#D1D9EF",
            border: 1,
            "&:hover": {
              borderColor: "#666",
            },
          }}
        >
          <FilterIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default CallHistoryHeader;

const refreshButtonStyles = {
  borderRadius: 2,
  px: 1.25,
  color: "#D1D9EF",
  border: 1,
  "&:hover": {
    borderColor: "#666",
  },
};
