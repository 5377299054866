export const buttonStyles = {
  delete: {
    width: "193px",
    height: "40px",
    borderRadius: 2,
    bgcolor: "#C4330F",
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    padding: 1.5,
    textTransform: "none",
    ":hover": { bgcolor: "#A42D0D" },
    "&.Mui-disabled": {
      backgroundColor: "#C4330F",
    },
  },
  cancel: {
    border: "1px solid #CCD2DE",
    width: "193px",
    height: "40px",
    borderRadius: 2,
    bgcolor: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    padding: 1.5,
    color: "#555555",
    textTransform: "none",
    ":hover": { bgcolor: "#F0F0F0" },
    "&.Mui-disabled": {
      backgroundColor: "#E95500",
    },
  },
  saveButton: {
    width: "193px",
    height: "40px",
    borderRadius: 2,
    bgcolor: "#FF5E00BF",
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    padding: 1.5,
    textTransform: "none",
    ":hover": { bgcolor: "#CC4B0099" },
    "&.Mui-disabled": {
      backgroundColor: "#E95500",
    },
  }
};
