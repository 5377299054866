import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Popover,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import {
  clearAccountReducerAction,
  logoutAction,
} from "../../Store/Account/Account.action";
import { clearProjectReducer } from "../../Projects/Store/projects.action";
import { clearCallSettingReducerAction } from "../../ProjectSection/CallSettings/Store/callSetting.action";
import { clearAudienceReducer } from "../../ProjectSection/AudienceSection/Store/Audience.action";
import { useHistory } from "react-router-dom";
import UpdateProfile from "./UpdateProfile";
import { shortenString } from "../../../../Constant/helper";

const EmailMenu = ({
  formData,
  setFormData,
  editModalOpen,
  setEditModalOpen,
  userDetails,
  updateUserDetails,
  styles,
  open,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { email } = userDetails;

  const handleMenuClick = async (menuItem) => {
    if (menuItem === "logout") {
      history.push("/");
      await dispatch(clearAccountReducerAction());
      await dispatch(clearProjectReducer());
      await dispatch(clearCallSettingReducerAction());
      await dispatch(clearAudienceReducer());
      await dispatch(logoutAction(history));
    }
    if (menuItem === "edit") {
      setEditModalOpen(true);
    }
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#111111",
            borderRadius: "8px",
            height: "40px",
            cursor: "pointer",
            px: 1,
          }}
        >
          {!open && (
            <IconButton
              onClick={handleOpenMenu}
              style={styles.mailStyle}
              aria-label="Open user menu"
            >
              {email?.charAt(0)?.toUpperCase()}
            </IconButton>
          )}
          {open && (
            <>
              <IconButton style={styles.mailStyle}>
                {email?.charAt(0)?.toUpperCase()}
              </IconButton>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#9E9FA1",
                }}
              >
                {shortenString(email, 18)}
              </Typography>
              <IconButton
                onClick={handleOpenMenu}
                sx={{
                  marginLeft: "8px",
                  color: "#9E9FA1",
                }}
                aria-label="More options"
              >
                <MoreVertIcon />
              </IconButton>
            </>
          )}
        </Box>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              minWidth: "150px",
              zIndex: 1300,
              marginLeft: "30px",
            },
            elevation: 3,
          }}
        >
          <MenuItem
            onClick={() => handleMenuClick("edit")}
            sx={{
              color: "#555555",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontSize: "13px",
              py: 1.5,
            }}
          >
            <Box display="flex" alignItems="center" gap="8px" width="100%">
              <i className="fas fa-edit"></i> Edit Profile
            </Box>
          </MenuItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <MenuItem
            onClick={() => handleMenuClick("logout")}
            sx={{
              color: "#555555",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontSize: "13px",
              py: 1.5,
            }}
          >
            <Box display="flex" alignItems="center" gap="8px" width="100%">
              <i className="fas fa-sign-out-alt"></i> Log out
            </Box>
          </MenuItem>
        </Popover>
      </Box>
      {editModalOpen && (
        <UpdateProfile
          open={open}
          formData={formData}
          setFormData={setFormData}
          updateUserDetails={updateUserDetails}
          handleModalClose={() => setEditModalOpen(!editModalOpen)}
        />
      )}
    </>
  );
};

export default EmailMenu;
