import React, { useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const createGradient = (ctx, colors) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  colors.forEach(({ stop, color }) => {
    gradient.addColorStop(stop, color);
  });
  return gradient;
};

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ chartData }) => {
  const chartRef = useRef();
  const { callStatus = [] } = chartData;

  const callStatusMap = Object.fromEntries(
    callStatus?.map(({ _id, count }) => [_id, count])
  );
  const totalCallsMade = callStatus.reduce(
    (total, { count }) => total + count,
    0
  );

  const totalFailed = callStatusMap["704"] ?? 0;
  const totalConnected =
    (callStatusMap["702"] ?? 0) + (callStatusMap["703"] ?? 0);
  const totalVoiceMail = callStatusMap["710"] ?? 0;
  const otherCalls =
    totalCallsMade - (totalFailed + totalConnected + totalVoiceMail);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "85%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} Calls`,
        },
      },
      centerText: {
        display: true,
        text1: "Total Calls",
        text2: totalCallsMade || "0",
      },
    },
  };

  const data: any = (() => {
    const totalValues = [
      totalConnected,
      totalFailed,
      totalVoiceMail,
      otherCalls,
    ];
    const allZero = totalValues.every((value) => value === 0);

    if (allZero) {
      return {
        labels: ["No Data"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#d3d3d3"],
            borderWidth: 2,
            borderColor: "#ffffff",
            borderRadius: 10,
          },
        ],
      };
    }

    return {
      labels: ["Call Connected", "Call Failed", "Voicemail", "Others"],
      datasets: [
        {
          data: totalValues,
          backgroundColor: (ctx) => {
            const chart = ctx.chart;
            const { ctx: canvasCtx } = chart;
            return [
              createGradient(canvasCtx, [
                { stop: 0, color: "rgba(143, 168, 255, 0.8)" },
                { stop: 1, color: "rgba(66, 101, 226, 0.8)" },
              ]),
              createGradient(canvasCtx, [
                { stop: 0, color: "rgba(255, 143, 143, 0.8)" },
                { stop: 1, color: "rgba(252, 105, 105, 0.8)" },
              ]),
              createGradient(canvasCtx, [
                { stop: 0, color: "rgba(223, 163, 255, 0.8)" },
                { stop: 1, color: "rgba(193, 83, 252, 0.8)" },
              ]),
              createGradient(canvasCtx, [
                { stop: 0, color: "rgba(255, 143, 224, 0.8)" },
                { stop: 1, color: "rgba(242, 93, 203, 0.8)" },
              ]),
            ];
          },
          borderWidth: 2,
          borderColor: "#ffffff",
          borderRadius: 10,
        },
      ],
    };
  })();

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw(chart) {
      const { text1, text2 } = chart.options.plugins.centerText || {};
      const ctx = chart.ctx;

      if (text1 && text2) {
        ctx.save();
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.fillStyle = "#555555";
        ctx.font = "500 16px Arial";
        ctx.fillText(text1, centerX, centerY - 20);

        ctx.fillStyle = "#000000";
        ctx.font = "700 26px Arial";
        ctx.fillText(text2, centerX, centerY + 15);

        ctx.restore();
      }
    },
  };

  const statusData = [
    {
      label: "Call Connected",
      value: totalConnected || 0,
      gradient:
        "linear-gradient(180deg, rgba(143, 168, 255, 0.8) 0%, rgba(66, 101, 226, 0.8) 100%)",
    },
    {
      label: "Call Failed",
      value: totalFailed || 0,
      gradient:
        "linear-gradient(237.96deg, rgba(255, 143, 143, 0.8) -12.91%, rgba(252, 105, 105, 0.8) 100.74%)",
    },
    {
      label: "Voicemail",
      value: totalVoiceMail || 0,
      gradient:
        "linear-gradient(185.1deg, rgba(223, 163, 255, 0.8) 7.62%, rgba(193, 83, 252, 0.8) 108.21%)",
    },
    {
      label: "Others",
      value: otherCalls || 0,
      gradient:
        "linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)",
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #D1D9EF",
        borderRadius: 2,
        padding: "24px 16px",
        height: "100%",
      }}
    >
      <Box
        style={{
          width: "240px",
          height: "240px",
          margin: "auto",
        }}
      >
        <Doughnut
          data={data}
          options={options}
          plugins={[centerTextPlugin]}
          ref={chartRef}
        />
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {statusData.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.75,
                backgroundColor: "#F8FAFE",
                padding: "8px 12px",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="#555555" fontSize={12} fontWeight={500}>
                  {item.label}
                </Typography>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    background: item.gradient,
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Typography color="#181818" fontSize={20} fontWeight={700}>
                {item?.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DoughnutChart;
