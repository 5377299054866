import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getCallSettingService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.CALL_SETTINGS}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveCallSettingService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.CALL_SETTINGS}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCallerIdOtpService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.GET_VOXI_OTP}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const resendCallerIdOtpService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.RESEND_OTP}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyCallerIdOtpService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.VERIFY_OTP}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveByocSettingService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CALL_SETTINGS.BYOC}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getGatewayAssignedNumberService = async (gateway) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.AI.GATEWAY_ASSIGNED_NUMBERS}/${gateway}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getHealthCheckService = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_AI}/system/health/check`);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const attachInboundNumberService = async (data, gateway) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AI.SAVE_INBOUND_NUMBER}/${gateway}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAllVoices = async (params) => {
  try {
    let res = await axiosClient.get(`${BASE_URL_AI}/voice/list`, {
      params: params,
    });
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
