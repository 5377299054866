import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCalllogsAction } from "../Store/Campaign.action";
import CommonModal from "../../../CommonComponents/Modal/CommonModal";
import DynamicTable from "../../../CommonComponents/Table/DynamicTable";
import moment from "moment";

function CallLogs({ call_uuid, show, toggle }) {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const init = async () => {
      let res = await dispatch(getCalllogsAction(call_uuid));

      setData(res);
    };

    init();

    return () => {
      setData([]);
    };
  }, []);

  return (
    <CommonModal
      show={show}
      heading="Logs"
      body={
        <DynamicTable
          columns={[
            {
              label: "Ingestion Time",
              render: (message) => {
                return `${moment(message?.ingestionTime).format(
                  "YYYY-MM-DD"
                )} ${moment(message?.ingestionTime).format("hh:mm a")}`;
              },
              styles: { textTransform: "none", textWrap: "nowrap" },
            },
            {
              label: "Message",
              render: (message) => message?.message,
            },
            {
              label: "Timestamp",
              render: (message) => {
                return `${moment(message?.timestamp).format(
                  "YYYY-MM-DD"
                )} ${moment(message?.timestamp).format("hh:mm a")}`;
              },
              styles: { textTransform: "none", padding: 2, textWrap: "nowrap" },
            },
          ]}
          data={data}
          showTotalRows={true}
          pagination={false}
          loading={false}
          handlePageChange={() => ""}
          rowCount={data?.length}
        />
      }
      toggle={toggle}
      size={1000}
      handleSave={false}
      footer={false}
      customClassName="call-logs-modal"
    />
  );
}

export default CallLogs;
